
















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { PersonAccountEntityModel } from '@common-src/entity-model/person-account-entity';
import { UserPwdModel, UserSetBaseInfoModel } from '@common-src/entity-model/user-entity';
import UserService, { UserPwdService } from '@common-src/service/user-v2';
import { ViewModeType } from '@common-src/model/enum';
import UserPwdComponent from '@common-src/pages/dashboard/user/user/user-pwd.vue';
import AccountSettingDialog from '@common-src/pages/dashboard/account-setting-dialog.vue';

@Component({
    components: {
        'user-pwd-dialog': UserPwdComponent,
        'account-setting-dialog': AccountSettingDialog
    }
})
export default class PersonSecurityComponent extends BaseComponent {
    private personAccountEntityModel: PersonAccountEntityModel = new PersonAccountEntityModel();
    private userIfo = {};

    mounted() {
        this.personAccountEntityModel.password = '********';
        new UserService().getUserInfo().then(res => {
            this.userIfo = res;
        }).catch(err => {
            console.log(err);
        });
    }
    newPasswordClick() {
        // const userPwdModel = new UserPwdModel();
        // userPwdModel.id = this.userIfo['id'];
        // (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserPwdService(), ViewModeType.UPDATE);
        // (this.$refs.userPwdFormDialog as any).dialogTitle = '修改密码';
        (this.$refs.accountSettingDialog as AccountSettingDialog).dialogOpen(true);
    }
    formDialogOK() {
        // relogin
    }
}
